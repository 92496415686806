import { getClient } from "src/api/api.ts";
import { HttpError } from "src/api/httpError.ts";
import { components } from "src/api/schema";
import { PageAndSortRequest, PageRequest, SortRequest } from "src/model/pageRequest.ts";
import { PaginatedResponse } from "src/model/paginatedResponse.ts";
import { ReportRequest } from "src/model/reportRequest.ts";
import {
  SimpleSubscription,
  Subscription,
  SubscriptionFilterOptions,
  SubscriptionFilterProps,
  SubscriptionPosition,
  SubscriptionSortBy,
} from "src/model/subscription";
import { ShipmentRegistrationLog } from "src/model/subscriptionLog.ts";
import { SubscriptionCreateRequest, SubscriptionUpdateRequest } from "src/model/subscriptionSaveRequest.ts";
import { addOneDay } from "src/utils/datetime.ts";

const mapFilter = (filter: SubscriptionFilterProps): components["schemas"]["SubscriptionFilterIn"] => {
  return {
    query: filter.query,
    companyId: filter.companyId,
    customerId: filter.customerId,
    excludeFailedSubscriptions: filter.excludeFailedSubscriptions,
    scacs: filter.scacs,
    portOfDischargeCodes: filter.portOfDischargeCodes,
    portOfLoadingCodes: filter.portOfLoadingCodes,
    etaPortOfDischargeFrom: filter.etaPortOfDischarge?.at(0)?.getTime(),
    etaPortOfDischargeTo: addOneDay(filter.etaPortOfDischarge?.at(1)?.getTime()),
    status: filter.status,
  };
};

export const fetchSubscription = async (subscriptionId: string): Promise<SimpleSubscription> => {
  const { data, error } = await getClient().GET("/API/iSEA5/V{version}/Subscription/{subscriptionId}", {
    params: {
      path: {
        version: "2.1",
        subscriptionId: subscriptionId,
      },
    },
  });

  if (data !== undefined) {
    return data;
  }
  if (error !== undefined) {
    if (error.statusCode === 503 && error.message !== null) {
      throw new Error(error.message);
    } else {
      throw new HttpError(error.statusCode, error.message);
    }
  }
  throw Error("Failed to fetch subscription");
};

export const fetchSubscriptions = async (
  filter: SubscriptionFilterProps,
  pagination: PageAndSortRequest<SubscriptionSortBy>
): Promise<PaginatedResponse<Subscription>> => {
  const { data, error } = await getClient().POST("/API/iSEA5/V{version}/Subscriptions/Search", {
    params: {
      path: { version: "2.1" },
      query: {
        limit: pagination.limit,
        offset: pagination.offset,
        sortBy: pagination.sortBy,
        sortDir: pagination.sortDir,
      },
    },
    body: mapFilter(filter),
  });

  if (data !== undefined) {
    return data;
  }
  if (error !== undefined && error.statusCode === 503 && error.message !== null) {
    throw new Error(error.message);
  }
  throw new Error("Failed to fetch subscriptions");
};

export const fetchSubscriptionFilterOptions = async (
  filter: SubscriptionFilterProps
): Promise<SubscriptionFilterOptions> => {
  const { data, error } = await getClient().POST("/API/iSEA5/V{version}/Subscriptions/FilterOptions", {
    params: {
      path: { version: "2.1" },
    },
    body: mapFilter(filter),
  });

  if (data !== undefined) {
    return data;
  }
  if (error !== undefined && error.statusCode === 503 && error.message !== null) {
    throw new Error(error.message);
  }
  throw new Error("Failed to fetch subscription filter");
};

export const fetchSubscriptionPositions = async (filter: SubscriptionFilterProps): Promise<SubscriptionPosition[]> => {
  const { data, error } = await getClient().POST("/API/iSEA5/V{version}/SubscriptionPositions", {
    params: {
      path: { version: "2.1" },
    },
    body: mapFilter(filter),
  });

  if (data !== undefined) {
    return data;
  }
  if (error !== undefined && error.statusCode === 503 && error.message !== null) {
    throw new Error(error.message);
  }
  throw new Error("Failed to fetch subscriptions");
};

export const fetchSubscriptionLogs = async (
  query: string | undefined,
  pagination: PageRequest
): Promise<PaginatedResponse<ShipmentRegistrationLog>> => {
  const { data, error } = await getClient().GET("/API/iSEA5/V{version}/SubscriptionLogs", {
    params: { path: { version: "2.1" }, query: { ...pagination, query: query } },
  });

  if (data !== undefined) {
    return data;
  }
  if (error !== undefined && error.statusCode === 503 && error.message !== null) {
    throw new Error(error.message);
  }
  throw new Error("Failed to fetch subscription logs");
};

export const fetchSubscriptionReportRequest = async (
  filter: SubscriptionFilterProps,
  sorting: SortRequest<SubscriptionSortBy>
): Promise<ReportRequest> => {
  const { data, error } = await getClient().POST("/API/iSEA5/V{version}/Subscriptions/Reports", {
    params: {
      path: { version: "2.1" },
      query: {
        sortBy: sorting.sortBy,
        sortDir: sorting.sortDir,
      },
    },
    body: mapFilter(filter),
  });

  if (data !== undefined) {
    return data;
  }
  if (error !== undefined && error.statusCode === 503 && error.message !== null) {
    throw new Error(error.message);
  }
  throw new Error("Failed to fetch subscription report request");
};

export const createSubscription = async (subscriptionSaveRequest: SubscriptionCreateRequest) => {
  const { data, error } = await getClient().POST("/API/iSEA5/V{version}/Subscriptions", {
    params: {
      path: {
        version: "2.1",
      },
    },
    body: subscriptionSaveRequest,
  });

  if (data !== undefined) {
    return;
  }
  if (error !== undefined && error.statusCode === 503 && error.message !== null) {
    throw new Error(error.message);
  }
  throw new Error("Failed to create subscription");
};

export const updateSubscription = async (
  subscriptionId: string,
  subscriptionUpdateRequest: SubscriptionUpdateRequest
) => {
  const { data, error } = await getClient().PUT("/API/iSEA5/V{version}/Subscriptions/{subscriptionId}", {
    params: {
      path: {
        version: "2.1",
        subscriptionId: subscriptionId,
      },
    },
    body: subscriptionUpdateRequest,
  });

  if (data !== undefined) {
    return;
  }
  if (error !== undefined && error.statusCode === 503 && error.message !== null) {
    throw new Error(error.message);
  }
  throw new Error("Failed to create subscription");
};

export const retrySubscriptionRegistration = async (subscriptionId: string) => {
  const { data, error } = await getClient().POST(
    "/API/iSEA5/V{version}/Subscriptions/{subscriptionId}/RetryRegistration",
    {
      params: {
        path: {
          version: "2.1",
          subscriptionId: subscriptionId,
        },
      },
    }
  );

  if (data !== undefined) {
    return;
  }
  if (error !== undefined) {
    if (error.statusCode === 503 && error.message !== null) {
      throw new Error(error.message);
    } else {
      throw new HttpError(error.statusCode, error.message);
    }
  }
  throw Error("Failed to retry subscription registration");
};
